import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import { BsEmojiSmile } from 'react-icons/bs';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const thankYou = () => {
  return (
    <Layout>
      <Helmet>
        <title>Thanks!</title>
      </Helmet>
      <Container className="text-center" style={{ height: '80vh' }}>
        <BsEmojiSmile style={{ fontSize: '8rem', margin: '2rem' }} />
        <h2>Thank you!</h2>
        <h2 style={{ fontWeight: 'lighter' }}>I will get back to you soon.</h2>
        <Button className="mt-2" size="md">
          <Link to="/" style={{ color: 'var(--featured-text)' }}>
            Home
          </Link>
        </Button>
      </Container>
    </Layout>
  );
};

export default thankYou;
